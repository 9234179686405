function FilterIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className || 'w-5 h-5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 21'
    >
      <path
        fillRule='evenodd'
        d='M16.22 3.714h1.407a.99.99 0 01.361.068c.115.045.219.111.306.194a.893.893 0 01.205.29.852.852 0 01-.205.973.948.948 0 01-.306.193.99.99 0 01-.361.068h-1.325a2.501 2.501 0 01-4.748 0H2.37a.971.971 0 01-.667-.261.87.87 0 01-.276-.632c0-.237.1-.464.276-.631a.971.971 0 01.667-.262h9.265a2.5 2.5 0 014.584 0zM13.2 5.5l-.03-.028a1.072 1.072 0 11.03.028zm-6.064 4.752c-.054.54-.51.962-1.064.962h-.001-.002a1.071 1.071 0 111.067-.962zm1.331-.823a2.501 2.501 0 00-4.793 0H2.371c-.25 0-.49.094-.667.261a.87.87 0 00-.276.632c0 .236.1.463.276.63a.971.971 0 00.667.262h1.44a2.5 2.5 0 004.519 0h9.297a.99.99 0 00.361-.068.947.947 0 00.306-.193.893.893 0 00.205-.29.851.851 0 00-.205-.973.948.948 0 00-.306-.193.99.99 0 00-.361-.068h-9.16zM15 16.214a1.069 1.069 0 01-1.87.714 1.071 1.071 0 111.87-.714zm-1.071 2.5c1.132 0 2.089-.753 2.396-1.785h1.304a.99.99 0 00.36-.068.95.95 0 00.307-.194.893.893 0 00.204-.29.852.852 0 00-.204-.973.99.99 0 00-.667-.262h-1.44a2.5 2.5 0 00-4.52 0H2.372c-.25 0-.49.095-.667.262a.87.87 0 00-.277.632c0 .237.1.464.277.631a.971.971 0 00.667.262h9.16a2.501 2.501 0 002.396 1.785z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export { FilterIcon };
