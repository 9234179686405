import { useNavigate } from '@remix-run/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';

import {
  EnumsGamePackAudience,
  EnumsGamePackCompetitionLevel,
  EnumsGamePackDifficulty,
  EnumsGamePackPlayStyle,
} from '@lp-lib/api-service-client/public';

import { useAnonAppAnalytics } from '../../../src/analytics/app/anon';
import { type AppAnalytics } from '../../../src/analytics/app/shared';
import { FilterIcon } from '../../../src/components/icons/FilterIcon';
import { MinusIcon } from '../../../src/components/icons/MinusIcon';
import { PlusIcon } from '../../../src/components/icons/PlusIcon';
import { XIcon } from '../../../src/components/icons/XIcon';
import { useFeatureQueryParam } from '../../../src/hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../src/hooks/useLiveCallback';
import { useOutsideClick } from '../../../src/hooks/useOutsideClick';
import {
  EnumsGamePackPlayHistoryFilter,
  type GameLikeFilterDuration,
} from '../../../src/types/game';
import { type GameLikeFilters } from '../../../src/types/game';
import { buildReactSelectStyles } from '../../../src/utils/react-select';

export type FilterOption<T> = {
  key: string;
  label: string;
  value: T;
};

export type GameLikeFilterOptions = {
  duration: FilterOption<GameLikeFilterDuration>[];
  difficulty: FilterOption<EnumsGamePackDifficulty>[];
  competitionLevel: FilterOption<EnumsGamePackCompetitionLevel>[];
  audience: FilterOption<EnumsGamePackAudience>[];
  playHistory: FilterOption<EnumsGamePackPlayHistoryFilter>[];
  // we only support one player count filter in the backend. we use an array here for consistency.
  playerCount: FilterOption<number>[];
  playStyle: FilterOption<EnumsGamePackPlayStyle>[];
};

export type GameLikeFilterName = keyof GameLikeFilterOptions;

export type Filter<T> = {
  name: GameLikeFilterName;
  displayName: string;
  options: FilterOption<T>[];
};

// note: not all filter keys have presets.
export const FilterPresets = {
  duration: {
    name: 'duration',
    displayName: 'Duration',
    options: [
      { key: '300_630', label: '5-10 Minutes', value: { min: 300, max: 630 } },
      {
        key: '600_1230',
        label: '10-20 Minutes',
        value: { min: 600, max: 1230 },
      },
      {
        key: '1200_1830',
        label: '20-30 Minutes',
        value: { min: 1200, max: 1830 },
      },
      {
        key: '1800_2730',
        label: '30-45 Minutes',
        value: { min: 1800, max: 2730 },
      },
      { key: '2700_INF', label: '45+ Minutes', value: { min: 2700 } },
    ],
  } as Filter<GameLikeFilterDuration>,
  difficulty: {
    name: 'difficulty',
    displayName: 'Difficulty',
    options: [
      {
        key: 'easy',
        label: 'Easy',
        value: EnumsGamePackDifficulty.GamePackDifficultyEasy,
      },
      {
        key: 'medium',
        label: 'Medium',
        value: EnumsGamePackDifficulty.GamePackDifficultyMedium,
      },
      {
        key: 'hard',
        label: 'Hard',
        value: EnumsGamePackDifficulty.GamePackDifficultyHard,
      },
    ],
  } as Filter<EnumsGamePackDifficulty>,
  competitionLevel: {
    name: 'competitionLevel',
    displayName: 'Competition Level',
    options: [
      {
        key: 'casual',
        label: 'Casual',
        value: EnumsGamePackCompetitionLevel.GamePackCompetitionLevelCasual,
      },
      {
        key: 'neutral',
        label: 'Neutral',
        value: EnumsGamePackCompetitionLevel.GamePackCompetitionLevelNeutral,
      },
      {
        key: 'competitive',
        label: 'Competitive',
        value:
          EnumsGamePackCompetitionLevel.GamePackCompetitionLevelCompetitive,
      },
    ],
  } as Filter<EnumsGamePackCompetitionLevel>,
  audience: {
    name: 'audience',
    displayName: 'Audience',
    options: [
      {
        key: 'globalFriendly',
        label: 'Global Friendly Content',
        value: EnumsGamePackAudience.GamePackAudienceGlobal,
      },
    ],
  } as Filter<EnumsGamePackAudience>,
  playHistory: {
    name: 'playHistory',
    displayName: 'Play History',
    options: [
      {
        key: 'played',
        label: 'Games I’ve Played',
        value: EnumsGamePackPlayHistoryFilter.GamePackPlayHistoryFilterPlayed,
      },
      {
        key: 'not-played',
        label: 'Games I Haven’t Played',
        value:
          EnumsGamePackPlayHistoryFilter.GamePackPlayHistoryFilterNotPlayed,
      },
    ],
  } as Filter<EnumsGamePackPlayHistoryFilter>,
  playStyle: {
    name: 'playStyle',
    displayName: 'Play Style',
    options: [
      {
        key: 'play-on-a-team',
        label: 'Play on a team',
        value: EnumsGamePackPlayStyle.GamePackPlayStylePlayOnATeam,
      },
      {
        key: 'play-all-together',
        label: 'Play all together',
        value: EnumsGamePackPlayStyle.GamePackPlayStylePlayAllTogether,
      },
    ],
  } as Filter<EnumsGamePackPlayStyle>,
} as const;

function transformFilter<T>(
  options: FilterOption<T>[] | undefined
): T[] | undefined {
  if (options === undefined) return undefined;
  if (options.length === 0) return undefined;
  return options.map((o) => o.value);
}

export function toGameLikeFilters(
  filterOptions: Partial<GameLikeFilterOptions>
): GameLikeFilters {
  const params: GameLikeFilters = {};
  params.duration = transformFilter(filterOptions.duration);
  params.difficulty = transformFilter(filterOptions.difficulty);
  params.competitionLevel = transformFilter(filterOptions.competitionLevel);
  params.audience = transformFilter(filterOptions.audience);
  params.playHistory = transformFilter(filterOptions.playHistory);
  if (filterOptions.playerCount && filterOptions.playerCount.length > 0) {
    params.playerCount = filterOptions.playerCount[0].value;
  }
  params.playStyle = transformFilter(filterOptions.playStyle);
  return params;
}

export function getPlayerCountAsFilterOption(
  playerCount: number
): FilterOption<number> {
  return {
    key: `${playerCount}`,
    label: `Players: ${playerCount}`,
    value: playerCount,
  };
}

export const MIN_PLAYER_COUNT_FILTER = 1;
export const MAX_PLAYER_COUNT_FILTER = 125;

export function parsePlayerCountFilter(
  playerCount: string | undefined | null
): FilterOption<number> | undefined {
  if (!playerCount) return undefined;
  const value = parseInt(playerCount, 10);
  if (
    !isNaN(value) &&
    value >= MIN_PLAYER_COUNT_FILTER &&
    value <= MAX_PLAYER_COUNT_FILTER
  ) {
    return getPlayerCountAsFilterOption(value);
  }
  return undefined;
}

function FilterSection<T>(props: {
  filter: Filter<T>;
  checked: FilterOption<T>[] | undefined;
  onChange: (filter: GameLikeFilterName, value: FilterOption<T>) => void;
}): JSX.Element {
  return (
    <section>
      <div className='text-sms tracking-wide font-medium'>
        {props.filter.displayName}
      </div>
      <div className='pl-2 pt-3 space-y-2.5'>
        {props.filter.options.map((f) => {
          const checked = props.checked?.find((c) => c.key === f.key);
          return (
            <label key={f.key} className='flex flex-row items-center'>
              <input
                type='checkbox'
                className='checkbox-dark'
                checked={!!checked}
                onChange={() => props.onChange(props.filter.name, f)}
              />
              <p className='ml-2 text-sms'>{f.label}</p>
            </label>
          );
        })}
      </div>
    </section>
  );
}

function FilterDropDown<T>(props: {
  filter: Filter<T>;
  selected: FilterOption<T> | undefined;
  onChange: (
    filter: GameLikeFilterName,
    value: FilterOption<T> | undefined
  ) => void;
}): JSX.Element {
  const styles = useMemo(() => buildReactSelectStyles<FilterOption<T>>(), []);
  return (
    <section>
      <div className='text-sms tracking-wide font-medium'>
        {props.filter.displayName}
      </div>
      <div className='pt-3 space-y-2.5'>
        <Select<FilterOption<T>>
          classNamePrefix='select-box-v2'
          className='w-full text-xs'
          styles={styles}
          value={props.selected}
          options={props.filter.options}
          onChange={(option) =>
            props.onChange(props.filter.name, option ?? undefined)
          }
          isClearable
          isSearchable={false}
          placeholder='Select filter...'
        />
      </div>
    </section>
  );
}

function PlayerCountSection(props: {
  value: FilterOption<number>[] | undefined;
  onChange: (value: number | undefined) => void;
}): JSX.Element {
  const [count, setCount] = useState(() => {
    const value = props.value?.[0]?.value;
    if (value) return value;
    return undefined;
  });

  const handleIncrement = () => {
    setCount((prev) => {
      prev = prev ?? 0;
      if (prev < MAX_PLAYER_COUNT_FILTER) {
        props.onChange(prev + 1);
        return prev + 1;
      }
      return prev;
    });
  };

  const handleDecrement = () => {
    setCount((prev) => {
      if (prev === undefined) return prev;
      if (prev === MIN_PLAYER_COUNT_FILTER) {
        props.onChange(undefined);
        return undefined;
      }
      if (prev > MIN_PLAYER_COUNT_FILTER) {
        props.onChange(prev - 1);
        return prev - 1;
      }
      return prev;
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setCount(undefined);
      props.onChange(undefined);
      return;
    }

    const value = parseInt(e.target.value, 10);
    if (
      !isNaN(value) &&
      value >= MIN_PLAYER_COUNT_FILTER &&
      value <= MAX_PLAYER_COUNT_FILTER
    ) {
      setCount(value);
      props.onChange(value);
    }
  };

  return (
    <section>
      <div className='text-sms tracking-wide font-medium'>Player Count</div>
      <div className='pt-3 flex items-center gap-2'>
        <button
          type='button'
          className='flex-none btn w-6 h-6 rounded-full bg-secondary border border-secondary flex items-center justify-center'
          disabled={count === undefined}
          onClick={handleDecrement}
        >
          <MinusIcon />
        </button>
        <input
          type='number'
          value={count ?? ''}
          onChange={handleInputChange}
          className='field flex-1 h-10 m-0 text-center'
          min={MIN_PLAYER_COUNT_FILTER}
          max={MAX_PLAYER_COUNT_FILTER}
        />
        <button
          type='button'
          className='flex-none btn w-6 h-6 rounded-full bg-secondary border border-secondary flex items-center justify-center'
          disabled={count === MAX_PLAYER_COUNT_FILTER}
          onClick={handleIncrement}
        >
          <PlusIcon />
        </button>
      </div>
    </section>
  );
}

type GameLikeFilterOptionsState = {
  filterOptions: Partial<GameLikeFilterOptions>;
  updateFilterOptions: (options: Partial<GameLikeFilterOptions> | null) => void;
};

export function ControlledGamePackFilters(
  props: {
    buttonClassName?: string;
    playHistory?: boolean;
    analytics: AppAnalytics;
  } & GameLikeFilterOptionsState
): JSX.Element {
  const v2Enabled = useFeatureQueryParam('gpv2-search-filters');
  const [visible, setVisible] = useState(false);

  const { filterOptions, updateFilterOptions } = props;

  const [controlledFilterOptions, setControlledFilterOptions] = useState<
    Partial<GameLikeFilterOptions>
  >({});

  useEffect(() => {
    if (visible) return;
    // when not visible, reset the controlled state.
    setControlledFilterOptions(filterOptions);
  }, [visible, filterOptions]);

  const handleChange: <K extends GameLikeFilterName>(
    filter: K,
    value: GameLikeFilterOptions[K][number]
  ) => void = (filter, value) => {
    setControlledFilterOptions((prev) => {
      const filterSet = prev[filter];
      if (filterSet === undefined) {
        return {
          ...prev,
          [filter]: [value],
        };
      }

      const index = filterSet.findIndex((o) => o.key === value.key);
      if (index === -1) {
        return {
          ...prev,
          [filter]: [...filterSet, value],
        };
      } else {
        const updated = [...filterSet];
        updated.splice(index, 1);
        return {
          ...prev,
          [filter]: updated,
        };
      }
    });
  };

  const handleReplace: <K extends GameLikeFilterName>(
    filter: K,
    value: GameLikeFilterOptions[K][number] | undefined
  ) => void = (filter, value) => {
    setControlledFilterOptions((prev) => {
      if (value === undefined) {
        return {
          ...prev,
          [filter]: [],
        };
      } else {
        return {
          ...prev,
          [filter]: [value],
        };
      }
    });
  };

  const handlePlayerCountChange = (value: number | undefined) => {
    setControlledFilterOptions((prev) => {
      if (value === undefined) {
        return {
          ...prev,
          playerCount: [],
        };
      }
      return {
        ...prev,
        playerCount: [getPlayerCountAsFilterOption(value)],
      };
    });
  };

  const handleApply = () => {
    updateFilterOptions(controlledFilterOptions);
    setVisible(false);

    const analyticsView = Object.fromEntries(
      Object.entries(controlledFilterOptions).map(([key, value]) => {
        return [key, value.map((v) => v.key)];
      })
    );

    props.analytics.trackLibraryFiltered(analyticsView);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setVisible(false));

  return (
    <div ref={ref} className='relative mx-2 first:mx-0 text-white'>
      <button
        type='button'
        className={`btn-secondary text-white flex items-center justify-center gap-1 h-10 w-33 ${props.buttonClassName}`}
        onClick={() => setVisible(!visible)}
      >
        <FilterIcon />
        Filters
      </button>

      {visible && (
        <div
          className={`
              flex flex-col border border-secondary rounded-xl
              px-5 py-4 mt-0.5 absolute bg-black whitespace-nowrap z-10
              min-w-72 text-white right-0
            `}
        >
          <div className='space-y-3'>
            <FilterDropDown<GameLikeFilterDuration>
              filter={FilterPresets.duration}
              selected={controlledFilterOptions.duration?.[0]}
              onChange={handleReplace}
            />
            <PlayerCountSection
              value={controlledFilterOptions.playerCount}
              onChange={handlePlayerCountChange}
            />
            {v2Enabled && (
              <FilterSection<EnumsGamePackDifficulty>
                filter={FilterPresets.difficulty}
                checked={controlledFilterOptions.difficulty}
                onChange={handleChange}
              />
            )}
            <FilterSection<EnumsGamePackCompetitionLevel>
              filter={FilterPresets.competitionLevel}
              checked={controlledFilterOptions.competitionLevel}
              onChange={handleChange}
            />
            {v2Enabled && (
              <FilterSection<EnumsGamePackAudience>
                filter={FilterPresets.audience}
                checked={controlledFilterOptions.audience}
                onChange={handleChange}
              />
            )}
            {props.playHistory && (
              <FilterDropDown<EnumsGamePackPlayHistoryFilter>
                filter={FilterPresets.playHistory}
                selected={controlledFilterOptions.playHistory?.[0]}
                onChange={handleReplace}
              />
            )}
          </div>
          <button
            type='button'
            className='btn-primary h-10 rounded-xl mt-5'
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
}

export function GameLikeFilterRemoveButtons(
  props: GameLikeFilterOptionsState
): JSX.Element | null {
  const { filterOptions, updateFilterOptions } = props;
  const numOfOptions = Object.values(filterOptions).reduce(
    (cnt, v) => cnt + v.length,
    0
  );

  const handleRemove = <T extends GameLikeFilterName>(
    name: T,
    option: GameLikeFilterOptions[T][number]
  ) => {
    const options = filterOptions[name];
    if (!options) return;
    const index = options.findIndex((o) => o.value === option.value);
    if (index === -1) return;
    const updated = options.map((o) => o);
    updated.splice(index, 1);
    updateFilterOptions({ [name]: updated });
  };

  if (numOfOptions <= 0) return null;

  return (
    <div className='flex w-full mt-2 text-white'>
      {Object.entries(filterOptions).map(([name, options]) =>
        options.map((o) => (
          <div
            key={`${name}-${o.key}`}
            className='flex flex-row items-center justify-center 
              bg-primary h-4.5 rounded-3xl text-sms p-4 mr-2'
          >
            <p className='mr-2'>{o.label}</p>
            <button
              type='button'
              className='btn'
              onClick={() => handleRemove(name as GameLikeFilterName, o)}
            >
              <XIcon />
            </button>
          </div>
        ))
      )}
    </div>
  );
}

export function AnonGamePackFilters(props: {
  filters?: Partial<GameLikeFilterOptions>;
}) {
  const filters = useMemo(() => props.filters ?? {}, [props.filters]);
  const navigate = useNavigate();
  const analytics = useAnonAppAnalytics();

  const updateFilterOptions = useLiveCallback(
    (options: Partial<GameLikeFilterOptions> | null) => {
      const params = new URLSearchParams();
      const entries = Object.entries(options ?? {});
      for (const [name, v] of entries) {
        params.delete(name);
        const keys = v?.map((o) => o.key) || [];
        for (const key of keys) {
          params.append(name, key);
        }
      }
      navigate(`/explore/search?${params.toString()}`);
    }
  );

  return (
    <ControlledGamePackFilters
      filterOptions={filters}
      updateFilterOptions={updateFilterOptions}
      analytics={analytics}
    />
  );
}
